<template>
  <div class="modal-card">
    <div class="modal-content">
      <div class="box">
        <div class="columns is-mobile">
          <div class="column">
            <p class="subtitle has-text-dark">{{ title }}</p>
          </div>
          <div class="column is-narrow">
            <a class="has-text-danger" @click="closeModal">Close</a>
          </div>
        </div>
        <OptionsTabs
          v-if="showTabs"
          :tab="filter"
          :make="make"
          @change-tab="filter = $event"
        />
        <OptionsConfirm v-if="!createdAt" @get-options="requestOptions" />
        <OptionsList
          v-else
          v-bind="{ hasOptions, createdAt, options: filteredOptions, filter }"
          @search="search = $event"
          @get-options="requestOptions"
          @open-contact-form="openContactForm"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'
export default {
  name: 'CompanionOptions',
  components: {
    OptionsList: () =>
      import('../components/section/options/CompanionOptionsList'),
    OptionsConfirm: () =>
      import('../components/section/options/CompanionOptionsConfirm'),
    OptionsTabs: () =>
      import('../components/section/options/CompanionOptionsTabs')
  },
  data: () => ({
    search: '',
    filter: null
  }),
  watch: {
    optionsData: 'setFilter'
  },
  mounted() {
    this.setFilter()
  },
  computed: {
    ...mapGetters('companion/options', [
      'optionsData',
      'createdAt',
      'vrm',
      'vin',
      'description',
      'make'
    ]),
    title() {
      const { filter, make, vrm, createdAt } = this
      const specCheck = !createdAt || filter === 'actualCazanaCoded'
      return specCheck
        ? `Spec Check for ${vrm}`
        : `${make} Actual Fit Data for ${vrm}`
    },
    showTabs() {
      return !!(this.optionsData?.actual && this.optionsData?.actualCazanaCoded)
    },
    hasOptions() {
      return !!this.optionsData
    },
    filteredOptions() {
      const value = this.search.toLowerCase()
      return this.options.filter(
        option =>
          option.code.toLowerCase().includes(value) ||
          option.description.toLowerCase().includes(value)
      )
    },
    options() {
      return this.optionsData?.[this.filter]?.options || []
    }
  },
  methods: {
    ...mapActions('companion/options', ['getOptions']),
    ...mapMutations('companion/options', ['reset']),
    async requestOptions() {
      try {
        this.$wait.start('getOptions')
        await this.getOptions()
      } finally {
        this.$wait.end('getOptions')
      }
    },
    setFilter() {
      this.filter = this.optionsData?.actualCazanaCoded
        ? 'actualCazanaCoded'
        : 'actual'
    },
    openContactForm() {
      this.$mxp.track(`options_modal_open_contact_form`)
      const { vrm, vin, description } = this
      const data = { vrm, vin, description, product: 'Options check' }
      this.$modal.open('contact/Contact', data)
    },
    closeModal() {
      const closeType = this.createdAt ? 'close' : 'cancel'
      this.$mxp.track(`companion_options_modal_${closeType}`)
      this.$modal.close()
    }
  }
}
</script>
